@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 320px;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.text-border-gradient {
  color: #ffffff;
  text-transform: capitalize;
  -webkit-text-stroke: 4px;
  -webkit-text-stroke-color: transparent;
  background: linear-gradient(180deg, #e8b938, #e8b938, #342917, #342917, #342917, #342917);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #ffffff;
  background-size: 200% 200%;
  box-sizing: border-box;
  text-shadow:
    -1px -1px 0 transparent,
    1px -1px 0 transparent,
    -1px 1px 0 transparent,
    1px 1px 0 transparent;
}
